import { GatewayApiClient } from "@radixdlt/babylon-gateway-api-sdk";
import { DFP2_RESOURCE_ADDRESS } from "~/lib/util";

export const useGatewayApi = GatewayApiClient.initialize({
	basePath: 'https://gateway.radix.live', //'https://radix-gateway01.astrolescent.com', // 'https://gateway.radix.live',
	applicationName: 'Astrolescent',
})

export async function getPairState(componentAddress: string) {
	const { state } = useGatewayApi;

	const componentDetails = await state.getEntityDetailsVaultAggregated(componentAddress);

	let baseAmount = 0;
	let quoteAmount = 0;
	let p0 = 0;

	for (let item of componentDetails.fungible_resources.items) {
		if (item.resource_address == DFP2_RESOURCE_ADDRESS) {
			quoteAmount = +item.vaults.items[0].amount;
		} else {
			baseAmount = +item.vaults.items[0].amount;
		}
	}

	for (let field of componentDetails.details?.state.fields) {
		
		if (field.type_name !== 'PairState') {
			continue;
		}
		for (let state of field.fields) {
			if (state.field_name !== 'p0') {
				continue;
			}
			
			p0 = +state.value		
		}
	}

	return {
		baseAmount,
		quoteAmount
	}
}

// export async function getTokenDetails(tokenAddress: string) {
// 	const { state } = useGatewayApi;

// 	const componentDetails = await state.getEntityDetailsVaultAggregated(componentAddress);
// }